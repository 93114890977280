import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Input, Button, Checkbox } from '../../components/base';
import { validateRequiredFields } from '../../utils/validation.util';
//import simpeelLogo from '../../assets/img/SIMPeeL.png';
import yplLogo from '../../assets/img/rsz_spin.png';
import {
  get, storageKeys,
} from '../../utils/storage.util';

class LoginComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    const token = get(storageKeys.USER_TOKEN);

    if (token) {
      history.push('/dashboard');
    }
  }

  handleFormChange(event) {
    const {
      name,
      value,
      type,
      checked = false,
    } = event.target;
    this.setState((prevState) => {
      let formattedValue = value;
      if (type === 'checkbox') {
        formattedValue = checked;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  async handleLogin(e) {
    e.preventDefault();
    const { form = {} } = this.state;
    const { handleLogin, history } = this.props;
    const error = validateRequiredFields(form.value, ['username', 'password']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleLogin(form.value, history.push);
    }
  }

  render() {
    const { form = {} } = this.state;
    const { t } = this.props;
    return (
      <div className="login">
        <div className="login__simpeel-logo">
          {/* <img src={simpeelLogo} alt="Simpeel" /> */}
        </div>
        <form
          className="login__form"
          onSubmit={this.handleLogin}
        >
          <img src={yplLogo} alt="ypl" />
          <h3 className="login__form-title">{t('login.title')}</h3>
          <div className="login__input-wrapper">
            <span className="icon-user login__input-wrapper--icon" />
            <Input
              noMargin
              name="username"
              placeholder="ad"
              onChange={this.handleFormChange}
              value={form.value.username || ''}
              error={form.error.username || ''}
            />
          </div>
          <div className="login__input-wrapper">
            <span className="icon-lock login__input-wrapper--icon" />
            <Input
              noMargin
              name="password"
              type="password"
              onChange={this.handleFormChange}
              value={form.value.password || ''}
              error={form.error.password || ''}
            />
          </div>
          <div className="login__input-wrapper">
            <Checkbox
              noMargin
              center
              name="remember_me"
              label={t('login.remember')}
              onChange={this.handleFormChange}
              checked={form.value.remember_me || false}
            />
          </div>
          <Button
            type="submit"
            title={t('login.button')}
          />
        </form>
      </div>
    );
  }
}
LoginComponent.propTypes = {
  handleLogin: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
};
LoginComponent.defaultProps = {
  handleLogin: noop,
  t: noop,
  history: {},
};

const Login = withTranslation()(LoginComponent);
export default Login;
