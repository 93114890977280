import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { CookiesProvider } from 'react-cookie';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import './assets/scss/main.scss';
import 'bootstrap/js/src/tab';
import App from './routes/App.route';
import initStore from './states/store';
import { initializeHTTPInterceptors } from './utils/axios.util';
import * as serviceWorker from './serviceWorker';


import './i18n';

const { store, persistor } = initStore();
initializeHTTPInterceptors(store);

window.onstorage = (e) => {
  console.log(e);
  if (e.key === 'persist:root') {
    window.location.reload(true);
  }
};

ReactDOM.render((
  <Suspense fallback="loading">
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Route path="/" name="App" component={App} />
          </Router>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </Suspense>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
