import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, result } from 'lodash';
import { Button, Input, Textarea, FileInput } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import {
  commaFormatted, sumActualSemesterAmount,
  sumExpectedSemesterAmount, normalizeAmount,
} from '../../../../../utils/transformer.util';
import { BUDGET_REQUIRED_FIELD } from '../../../../../constants/finance/budgeting/budget.constant';
import { validateRequiredFields } from '../../../../../utils/validation.util';
import { deleteConfirmation, errorAlert } from '../../../../../utils/alert.util';

export default class BudgetField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
    };
    this.tempValue = JSON.stringify(props.data);
    this.onToggleEdit = this.onToggleEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onDeleteSemester = this.onDeleteSemester.bind(this);
    this.onChangeRecommendation = this.onChangeRecommendation.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { data: nextData } = nextProps;
    const { data } = this.props;
    return (JSON.stringify(data) !== JSON.stringify(nextData)) || (JSON.stringify(nextState) !== JSON.stringify(this.state));
  }

  onToggleEdit() {
    const { onUpdateForm, idx } = this.props;
    this.setState((prevState) => {
      if (prevState.edit) {
        onUpdateForm(JSON.parse(this.tempValue), idx);
      }
      return {
        edit: !prevState.edit,
      };
    });
  }

  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { onChange, idx } = this.props;
    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });
  }

  onChangeRecommendation(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { onChangeRecommendation, idx } = this.props;
    onChangeRecommendation({
      target: {
        name,
        value: normalizeAmount(value),
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });
  }

  async onSave() {
    const {
      handleEditBudgetDetail, code, onUpdateForm,
      idx, data, onUpdateError,
    } = this.props;
    const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    const payload = { ...data };
    if (expectedAmount !== actualAmount) {
      error.amount = 'Jumlah salah';
    }
    if (!isEmpty(error)) {
      onUpdateError(data, error, idx);
      errorAlert({
        title: 'Data Ada Yang Salah',
      });
    } else {
      try {
        if (payload.head.hasOwnProperty('unique_id')) {
          payload.head = payload.head.unique_id;
        }
        const res = await handleEditBudgetDetail({ ...payload, code });
        onUpdateForm(res.data.data[0], idx);
        this.tempValue = JSON.stringify(res.data.data[0]);
        this.onToggleEdit();
      } catch (err) {
        // err
      }
    }
  }

  async onDeleteSemester() {
    const {
      status, onDeleteSemester, handleDeleteBudgetDetail,
      data, isNew,
    } = this.props;
    if (status === 'ADD' || isNew) {
      onDeleteSemester();
    } else {
      const willDelete = await deleteConfirmation();
      if (willDelete) {
        try {
          await handleDeleteBudgetDetail(data.unique_id);
          onDeleteSemester();
        } catch (err) {
          // err
        }
      }
    }
  }

  async onFileUpload(event) {
    const { handleFileUpload, onChange, idx } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const file = files[0];

    if(file) {
      const filename = file.name;
      const res = await handleFileUpload(file);

      onChange({
        target: {
          name: 'file',
          value: {
            path: res.path,
            filename
          },
          dataset: {
            ...dataset,
            inputArray: true,
            arrayPosition: idx,
          },
        },
      });
    }
  }

  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }

  render() {
    const { edit } = this.state;
    const {
      idx, error, data, coa,
      type, status,
      isNew, onSearchCoa, user, recommendations, workflow, handleFileUpload,
      handleFileDownload,
    } = this.props;
    const { user_group } = user;
    const editing = status === 'ADD' || edit || isNew;
    const canEdit = editing && type !== 'RAPBU';
    const expectedAmount = type === 'RAPBU' ? commaFormatted(data.total) : sumExpectedSemesterAmount(data);
    const canEditRecommendation = user_group.name === 'Korektor Perwakilan' || user_group.name === 'Manager Keuangan';
    const isRapbu = (type === 'INCOME' || type === 'OUTCOME') ? false : true;
    const isBendahara = (user_group && user_group.name === 'Bendahara') ? true : false;

    return (
      <tr>
        <td>
          <div>
            <SelectCoa
              noMargin
              inputArray
              arrayPosition={idx}
              edit={canEdit}
              name="parameter_code"
              value={data.parameter_code || {}}
              error={error.parameter_code}
              coa={coa}
              onClick={this.onFormChange}
              placeholder="Pilih Kode"
              rightIcon="icon-search"
              onSearchCoa={onSearchCoa}
            />
          </div>
        </td>
        <td>
          <div className="manage-budget__row manage-budget__row--30">
            {result(data, 'parameter_code.title', '')}
          </div>
        </td>
        <td>
          <div className="manage-budget__row manage-budget__row--30">
            <Textarea
              noMargin
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="desc"
              placeholder="Isi Deskripsi"
              onChange={this.onFormChange}
              value={data.desc}
            />
          </div>
        </td>
        <td key="budget_field_td_arr_1">
          <div className="manage-budget__row small nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="quantity"
              data-input-type="number"
              placeholder="Isi Kuantitas"
              onChange={this.onFormChange}
              value={data.quantity}
              error={error.quantity}
            />
          </div>
        </td>
        <td key="budget_field_td_arr_2">
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="price"
              data-input-type="number"
              placeholder="Isi Satuan"
              onChange={this.onFormChange}
              value={data.price}
              error={error.price}
            />
          </div>
        </td>
        <td key="budget_field_td_arr_3">
          <div className="manage-budget__row small nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="term"
              data-input-type="number"
              placeholder="Isi Periode"
              onChange={this.onFormChange}
              value={data.term}
              error={error.term}
            />
          </div>
        </td>
        <td>
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="ypl"
              data-input-type="number"
              placeholder="Isi YPL"
              onChange={this.onFormChange}
              value={data.ypl}
              error={error.ypl}
            />
          </div>
        </td>
        <td>
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="committee"
              data-input-type="number"
              placeholder="Isi Committee"
              onChange={this.onFormChange}
              value={data.committee}
              error={error.committee}
            />
          </div>
        </td>
        <td>
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="intern"
              data-input-type="number"
              placeholder="Isi Intern"
              onChange={this.onFormChange}
              value={data.intern}
              error={error.intern}
            />
          </div>
        </td>
        <td>
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="bos"
              data-input-type="number"
              placeholder="Isi BOS"
              onChange={this.onFormChange}
              value={data.bos}
              error={error.bos}
            />
          </div>
        </td>
        <td>
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              name="total"
              edit={canEdit}
              value={expectedAmount}
              error={error.amount}
            />
          </div>
        </td>
        <td>
          <div className="manage-budget__row">
            <FileInput
              data-input-type="file"
              noMargin
              type="file"
              name="file"
              edit={canEdit}
              onChange={this.onFileUpload}
              fileName={data.file ? data.file.name : ''}
              displayName={data.file ? data.file.display_name : ''}
              onRemoveFile={this.removeFile}
              onDownloadFile={handleFileDownload}
            />
          </div>
        </td>
        <td>
          <div className="icon">
            {
              isNew
              && <Button onClick={this.onDeleteSemester} icon="icon-trash-o" />
            }
            {
              (!isNew && !edit)
              && (
                <div className="manage-budget__action-button">
                  <Button onClick={this.onToggleEdit} icon="icon-file-o" />
                  <Button onClick={this.onDeleteSemester} icon="icon-trash-o" />
                </div>
              )
            }
            {
              (!isNew && edit)
              && (
                <div className="manage-budget__action-button">
                  <Button onClick={this.onSave} icon="icon-check" />
                  <Button onClick={this.onToggleEdit} icon="icon-close" />
                </div>
              )
            }
          </div>
        </td>
      </tr>
    );
  }
}
BudgetField.propTypes = {
  isNew: PropTypes.bool,
  idx: PropTypes.number.isRequired,
  onDeleteSemester: PropTypes.func,
  onChange: PropTypes.func,
  onChangeRecommendation: PropTypes.func,
  handleEditBudgetDetail: PropTypes.func,
  handleDeleteBudgetDetail: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFileDownload: PropTypes.func,
  onUpdateForm: PropTypes.func,
  onUpdateError: PropTypes.func,
  onRemoveFile: PropTypes.func,
  error: PropTypes.object,
  data: PropTypes.object,
  coa: PropTypes.object.isRequired,
  code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onSearchCoa: PropTypes.func,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  workflow: PropTypes.array,
};
BudgetField.defaultProps = {
  isNew: false,
  onDeleteSemester: noop,
  onChange: noop,
  onChangeRecommendation: noop,
  handleEditBudgetDetail: noop,
  handleDeleteBudgetDetail: noop,
  handleFileUpload: noop,
  handleFileDownload: noop,
  onUpdateForm: noop,
  onUpdateError: noop,
  onRemoveFile: noop,
  error: {},
  data: {},
  onSearchCoa: noop,
  user: null,
  recommendations: {},
  workflow: null,
};
