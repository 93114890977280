import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
} from 'react-router-dom';
import { noop } from 'lodash';
import { getUnitOptions } from '../states/thunks/options.thunk';
import { setWorkingUnit, handleLogout } from '../states/thunks/auth.thunk';
import { getNotifications, updateNotificationStatus } from '../states/thunks/common.thunk';
import { journalAcceptReconciliation } from '../states/thunks/finance.thunk';
import Header from '../components/Header/Header.component';
import Sidebar from '../components/Sidebar/Sidebar.component';
import Home from '../pages/Home.page';
import AlertContextProvider from '../contexts/AlertContext';

import ManageRegistration from '../pages/Student/ManageRegistration.student.page';
import StudentList from '../pages/Student/StudentList.student.page';
import UploadStudent from '../pages/Student/UploadStudent.student.page';
import StudentNumber from '../pages/Student/report/StudentNumber.student.page';
import Klapper from '../pages/Student/report/Klapper.student.page';
import StudentKbk from '../pages/Student/report/StudentKbk.student.page';
import StudentGrade from '../pages/Student/report/StudentGrade.student.page';
import StudentBook from '../pages/Student/report/StudentBook.student.page';

import ManageStaff from '../pages/Personnel/ManageStaff.personnel.page';
import ManageStaffUnit from '../pages/Personnel/ManageStaffUnit.personnel.page';
import ViewStaff from '../pages/Personnel/ViewStaff.personnel.page';
import StaffList from '../pages/Personnel/StaffList.personnel.page';
import StaffListUnit from '../pages/Personnel/StaffListUnit.personnel.page';
import UploadStaff from '../pages/Personnel/UploadStaff.personnel.page';

import SalaryReport from '../pages/Personnel/Report/SalaryReport.report.page';
import PayrollList from '../pages/Personnel/Payroll/PayrollList.payroll.page';
import DataPayroll from '../pages/Personnel/Payroll/DataPayroll.page';
import ManagePayroll from '../pages/Personnel/Payroll/ManagePayroll.payroll.page';
import ManagePayrollList from '../pages/Personnel/Payroll/ManagePayrollList.page';
import ManagePayrollPrintList from '../pages/Personnel/Payroll/ManagePayrollPrintList.page';
import PayrollSlipInput from '../pages/Personnel/Payroll/PayrollSlipInput.page';
import PayrollSlipPreview from '../pages/Personnel/Payroll/PayrollSlipPreview.page';
import PayrollReport from '../pages/Personnel/Report/PayrollReport.report.page';
import ManagePotonganIntern from '../pages/Personnel/Payroll/ManagePotonganIntern.page';

import InformationLetter from '../pages/Personnel/Letter/InformationLetter.letter.page';
import ManageInformationLetter from '../pages/Personnel/Letter/ManageInformationLetter.letter.page';
import PreviewSuratKeputusan from '../pages/Personnel/Letter/PreviewSuratKeputusan.letter.page';

import AssignmentLetter from '../pages/Personnel/Letter/AssignmentLetter.letter.page';
import ManageAssignmentLetter from '../pages/Personnel/Letter/ManageAssignmentLetter.letter.page';
import PreviewSuratTugas from '../pages/Personnel/Letter/PreviewSuratTugas.letter.page';

import JobApplication from '../pages/Personnel/Letter/JobApplication.letter.page';
import ManageJobApplication from '../pages/Personnel/Letter/ManageJobApplication.letter.page';

import Request from '../pages/Personnel/Letter/Request.letter.page';
import ManageRequest from '../pages/Personnel/Letter/ManageRequest.letter.page';

import Discontinuance from '../pages/Personnel/Letter/Discontinuance.letter.page';
import ManageDiscontinuance from '../pages/Personnel/Letter/ManageDiscontinuance.letter.page';

import DiscontinuanceDescription from '../pages/Personnel/Letter/DiscontinuanceDescription.letter.page';
import ManageDiscontinuanceDescription from '../pages/Personnel/Letter/ManageDiscontinuanceDescription.letter.page';

import Assignment from '../pages/Personnel/Letter/Assignment.letter.page';
import ManageAssignment from '../pages/Personnel/Letter/ManageAssignment.letter.page';

import Retire from '../pages/Personnel/Letter/Retire.letter.page';
import ManageRetire from '../pages/Personnel/Letter/ManageRetire.letter.page';

import Promotion from '../pages/Personnel/Letter/Promotion.letter.page';
import ManagePromotion from '../pages/Personnel/Letter/ManagePromotion.letter.page';

import EmployeeContract from '../pages/Personnel/Letter/EmployeeContract.letter.page';
import ManageEmployeeContract from '../pages/Personnel/Letter/ManageEmployeeContract.letter.page';

import TeacherContract from '../pages/Personnel/Letter/TeacherContract.letter.page';
import ManageTeacherContract from '../pages/Personnel/Letter/ManageTeacherContract.letter.page';

import Position from '../pages/Personnel/Letter/Position.letter.page';
import ManagePosition from '../pages/Personnel/Letter/ManagePosition.letter.page';

import Permanent from '../pages/Personnel/Letter/Permanent.letter.page';
import ManagePermanent from '../pages/Personnel/Letter/ManagePermanent.letter.page';

import JobPeriod from '../pages/Personnel/Report/JobPeriod.report.page';
import TeacherEmployee from '../pages/Personnel/Report/TeacherEmployee.report.page';

import Upload from '../pages/Upload.page';
import Finance from '../pages/Finance/Finance.page';
import Budgeting from '../pages/Finance/Budgeting/Budgeting.page';
import Budget from '../pages/Finance/Budgeting/Budget/Budget.finance.page';
import AddBudget from '../pages/Finance/Budgeting/Budget/AddBudget.finance.page';
import BudgetType from '../pages/Finance/Budgeting/Budget/BudgetType.finance.page';
import ManageBudget from '../pages/Finance/Budgeting/Budget/ManageBudget.finance.page';
import ManageBudgetRapbu from '../pages/Finance/Budgeting/Budget/ManageBudgetRapbu.finance.page';
import UploadRapbu from '../pages/Finance/Budgeting/Budget/UploadRapbu.finance.page';
import AddUploadRapbu from '../pages/Finance/Budgeting/Budget/AddUploadRapbu.finance.page';
import BudgetRecaps from '../pages/Finance/Budgeting/Budget/BudgetRecaps.finance.page';

//import APBU from '../pages/Finance/Budgeting/Budget/APBU.finance.page'

import NonBudget from '../pages/Finance/Budgeting/NonBudget/NonBudget.finance.page';
import ManageNonBudget from '../pages/Finance/Budgeting/NonBudget/ManageNonBudget.finance.page';

import BudgetTransfer from '../pages/Finance/Budgeting/BudgetTransfer/BudgetTransfer.finance.page';
import ManageBudgetTransfer from '../pages/Finance/Budgeting/BudgetTransfer/ManageBudgetTransfer.finance.page';

import FundRequest from '../pages/Finance/Budgeting/FundRequest/FundRequest.finance.page';
import ManageFundRequest from '../pages/Finance/Budgeting/FundRequest/ManageFundRequest.finance.page';

import Realization from '../pages/Finance/Budgeting/Realization/Realization.finance.page';
import ManageRealization from '../pages/Finance/Budgeting/Realization/ManageRealization.finance.page';

import Bookkeeping from '../pages/Finance/Bookkeeping/Bookkeeping.page';
import CashJournal from '../pages/Finance/Bookkeeping/CashJournal/CashJournal.page';
import CashJournalClosingList from '../pages/Finance/Bookkeeping/CashJournal/CashJournalClosingList.page';
import CashJournalClosing from '../pages/Finance/Bookkeeping/CashJournal/CashJournalClosing.page';
import DetailCashJournal from '../pages/Finance/Bookkeeping/CashJournal/DetailCashJournal.page';
import AddCashJournal from '../pages/Finance/Bookkeeping/CashJournal/ManageCashJournal.page';
import BankJournal from '../pages/Finance/Bookkeeping/BankJournal/BankJournal.page';
import DetailBankJournal from '../pages/Finance/Bookkeeping/BankJournal/DetailBankJournal.page';
import AddBankJournal from '../pages/Finance/Bookkeeping/BankJournal/ManageBankJournal.page';
import BeginningBalance from '../pages/Finance/Bookkeeping/BeginningBalance.page';

import Journals from '../pages/Finance/Bookkeeping/Journals/Journals.bookkeeping.page';

import H2HJournal from '../pages/Finance/Bookkeeping/H2HJournal/H2HJournal.page';
import ManageH2HJournal from '../pages/Finance/Bookkeeping/H2HJournal/ManageH2HJournal.page';

import PaymentJournal from '../pages/Finance/Bookkeeping/PaymentJournal/PaymentJournal.finance.page';
import ManagePaymentJournal from '../pages/Finance/Bookkeeping/PaymentJournal/ManagePaymentJournal.finance.page';

import AdjustmentJournal from '../pages/Finance/Bookkeeping/AdjustmentJournal/AdjustmentJournal.bookkeeping.page';
import ManageAdjustmentJournal from '../pages/Finance/Bookkeeping/AdjustmentJournal/ManageAdjustmentJournal.bookkeeping.page';
import ViewAdjustmentJournal from '../pages/Finance/Bookkeeping/AdjustmentJournal/ViewAdjustmentJournal.bookkeeping.page';

import ClosingJournal from '../pages/Finance/Bookkeeping/ClosingJournal/ClosingJournal.bookkeeping.page';
import ManageClosingJournal from '../pages/Finance/Bookkeeping/ClosingJournal/ManageClosingJournal.bookkeeping.page';

import NonKasJournal from '../pages/Finance/Bookkeeping/NonKasJournal/NonKasJournal.bookkeeping.page';
import ManageNonKasJournal from '../pages/Finance/Bookkeeping/NonKasJournal/ManageNonKasJournal.bookkeeping.page';

import GeneralJournal from '../pages/Finance/Bookkeeping/GeneralJournal/GeneralJournal.bookkeeping.page';
import ManageGeneralJournal from '../pages/Finance/Bookkeeping/GeneralJournal/ManageGeneralJournal.bookkeeping.page';

import CashJournalReport from '../pages/Finance/Report/CashJournal.report.page';
import BankJournalReport from '../pages/Finance/Report/BankJournal.report.page';
import BigBookReport from '../pages/Finance/Report/BigBook.report.page';
import MonthlyReport from '../pages/Finance/Report/Monthly.report.page';
import DailyAndMonthly from '../pages/Finance/Report/DailyAndMonthly.report.page';
import ProfitAndLost from '../pages/Finance/Report/ProfitAndLost.report.page';
import Balance from '../pages/Finance/Report/Balance.report.page';
import Payment from '../pages/Finance/Report/Payment.report.page';
import PaymentRecap from '../pages/Finance/Report/PaymentRecap.report.page';
import Supervision from '../pages/Finance/Report/Supervision.report.page';


import ManagePassword from '../pages/Settings/ManagePassword.page';

import { remove, storageKeys } from '../utils/storage.util';

import { SIDEBAR__DASHBOARD_MENU } from '../constants/menu.constant';

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: window.innerWidth > 900,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.onPressHome = this.onPressHome.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.onClickProfile = this.onClickProfile.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this._onClickBreadcrumb = this._onClickBreadcrumb.bind(this);
  }

  onPressHome() {
    const { history } = this.props;
    history.replace('/dashboard');
  }

  onClickLogout() {
    const { history } = this.props;
    history.replace('/login');
    remove(storageKeys.USER_TOKEN);
  }

  onClickProfile() {
    const { history } = this.props;
    history.push('/dashboard/profile');
  }

  onClickMenu(path, attributes) {
    const { history } = this.props;
    history.push(path, attributes);
  }

  onLogout() {
    const { onLogout, history } = this.props;
    onLogout(history.push);
  }

  _onClickBreadcrumb(index) {
    const { location, history } = this.props;
    const listPath = location.pathname.split('/');
    const newPath = listPath.slice(0, index + 2);
    history.push(newPath.join('/'));
  }

  toggleSidebar() {
    this.setState(prevState => ({
      showSidebar: !prevState.showSidebar,
    }));
  }

  render() {
    const { showSidebar } = this.state;
    const {
      match, location, user, units, onSearchUnit, handleSetWorkingUnit,
      handleGetNotifications, handleUpdateNotificationStatus,
      notifications, history, handleAcceptReconciliation,
    } = this.props;
    const { user_group = {} } = user;
    const userGroup = user_group.name;
    const menuEnabledGroups = [
      'Bendahara', 'Personalia', 'Manager Keuangan', 'Korektor Pusat','Personalia','Admin', 'Super Admin'
    ];

    const menuDisabled = menuEnabledGroups.includes(userGroup);
    return (
      <div className="dashboard">
        <Header
          onClickMenu={this.toggleSidebar}
          pathname={location.pathname}
          onClickBreadcrumb={this._onClickBreadcrumb}
          onSearchUnit={onSearchUnit}
          setWorkingUnit={handleSetWorkingUnit}
          user={user}
          units={units}
          history={history}
          notifications={notifications}
          handleGetNotifications = {handleGetNotifications}
          handleAcceptReconciliation = {handleAcceptReconciliation}
          updateNotificationStatus = {handleUpdateNotificationStatus}
        />
        <div className="dashboard__wrapper">
          <Sidebar
            show={showSidebar}
            sidebarMenu={SIDEBAR__DASHBOARD_MENU}
            onClickMenu={this.onClickMenu}
            onLogout={this.onLogout}
            user={user}
          />
          <div className="dashboard__main-panel">
            <div className="dashboard__content-wrapper">
              <AlertContextProvider>
                <Route exact path={match.path} component={Home} />

                <Route exact path={`${match.path}/kesiswaan/pendaftaran`} component={ManageRegistration} />
                <Route exact path={`${match.path}/kesiswaan/data-siswa`} component={StudentList} />
                <Route exact path={`${match.path}/kesiswaan/upload`} component={UploadStudent} />
                <Route exact path={`${match.path}/kesiswaan/laporan/nis`} component={StudentNumber} />
                <Route exact path={`${match.path}/kesiswaan/laporan/klapper`} component={Klapper} />
                <Route exact path={`${match.path}/kesiswaan/laporan/kbk`} component={StudentKbk} />
                <Route exact path={`${match.path}/kesiswaan/laporan/nilai-siswa`} component={StudentGrade} />
                <Route exact path={`${match.path}/kesiswaan/laporan/buku-siswa`} component={StudentBook} />

                <Route exact path={`${match.path}/personalia/data-payroll`} component={DataPayroll} />
                <Route exact path={`${match.path}/personalia/data-payroll/payroll`} component={PayrollList} />
                <Route exact path={`${match.path}/personalia/data-payroll/list-payroll/:nip`} component={ManagePayrollList} />
                <Route exact path={`${match.path}/personalia/data-payroll/view-payroll`} component={ManagePayroll} />
                <Route excat path={`${match.path}/personalia/laporan/slip-gaji/:nip`} component={ManagePayrollPrintList} />
                <Route exact path={`${match.path}/personalia/laporan/input-payroll`} component={PayrollSlipInput} />
                <Route exact path={`${match.path}/personalia/laporan/preview-payroll`} component={PayrollSlipPreview} />
                <Route exact path={`${match.path}/personalia/data-payroll/potongan-intern`} component={ManagePotonganIntern} />

                <Route exact path={`${match.path}/personalia/laporan/payroll`} component={PayrollReport} />

                <Route exact path={`${match.path}/personalia/kelola-staff`} component={ManageStaff} />
                <Route exact path={`${match.path}/personalia/kelola-staff-unit`} component={ManageStaffUnit} />
                <Route exact path={`${match.path}/personalia/view-staff`} component={ViewStaff} />
                <Route exact path={`${match.path}/personalia/data-staff`} component={StaffList} />
                <Route exact path={`${match.path}/personalia/data-staff-unit`} component={StaffListUnit} />
                <Route exact path={`${match.path}/personalia/upload-staff`} component={UploadStaff} />

                <Route exact path={`${match.path}/personalia/surat-keputusan/:nip`} component={InformationLetter} />
                <Route exact path={`${match.path}/personalia/tambah-surat-keputusan/:nip`} component={ManageInformationLetter} />
                <Route exact path={`${match.path}/personalia/edit-surat-keputusan/:id`} component={ManageInformationLetter} />
                <Route exact path={`${match.path}/personalia/preview-surat-keputusan`} component={PreviewSuratKeputusan} />

                <Route exact path={`${match.path}/personalia/surat-tugas/:nip`} component={AssignmentLetter} />
                <Route exact path={`${match.path}/personalia/tambah-surat-tugas/:nip`} component={ManageAssignmentLetter} />
                <Route exact path={`${match.path}/personalia/edit-surat-tugas/:id`} component={ManageAssignmentLetter} />
                <Route exact path={`${match.path}/personalia/preview-surat-tugas`} component={PreviewSuratTugas} />

                <Route exact path={`${match.path}/personalia/surat/lamaran-kerja`} component={JobApplication} />
                <Route exact path={`${match.path}/personalia/surat/tambah-lamaran-kerja`} component={ManageJobApplication} />

                <Route exact path={`${match.path}/personalia/surat/permohonan-barang-jasa`} component={Request} />
                <Route exact path={`${match.path}/personalia/surat/tambah-permohonan-barang-jasa`} component={ManageRequest} />

                <Route exact path={`${match.path}/personalia/surat/pemberhentian`} component={Discontinuance} />
                <Route exact path={`${match.path}/personalia/surat/tambah-pemberhentian`} component={ManageDiscontinuance} />

                <Route exact path={`${match.path}/personalia/surat/keterangan-pemberhentian`} component={DiscontinuanceDescription} />
                <Route exact path={`${match.path}/personalia/surat/tambah-keterangan-pemberhentian`} component={ManageDiscontinuanceDescription} />

                <Route exact path={`${match.path}/personalia/surat/penugasan`} component={Assignment} />
                <Route exact path={`${match.path}/personalia/surat/tambah-penugasan`} component={ManageAssignment} />

                <Route exact path={`${match.path}/personalia/surat/pensiun`} component={Retire} />
                <Route exact path={`${match.path}/personalia/surat/tambah-pensiun`} component={ManageRetire} />

                <Route exact path={`${match.path}/personalia/surat/kenaikan`} component={Promotion} />
                <Route exact path={`${match.path}/personalia/surat/tambah-kenaikan`} component={ManagePromotion} />

                <Route exact path={`${match.path}/personalia/surat/karyawan-kontrak`} component={EmployeeContract} />
                <Route exact path={`${match.path}/personalia/surat/tambah-karyawan-kontrak`} component={ManageEmployeeContract} />

                <Route exact path={`${match.path}/personalia/surat/guru-kontrak`} component={TeacherContract} />
                <Route exact path={`${match.path}/personalia/surat/tambah-guru-kontrak`} component={ManageTeacherContract} />

                <Route exact path={`${match.path}/personalia/surat/jabatan`} component={Position} />
                <Route exact path={`${match.path}/personalia/surat/tambah-jabatan`} component={ManagePosition} />

                <Route exact path={`${match.path}/personalia/surat/pengangkatan`} component={Permanent} />
                <Route exact path={`${match.path}/personalia/surat/tambah-pengangkatan`} component={ManagePermanent} />

                <Route exact path={`${match.path}/upload`} component={Upload} />
                <Route exact path={`${match.path}/keuangan`} component={Finance} />
                <Route exact path={`${match.path}/keuangan/penganggaran`} component={Budgeting} />
                <Route exact path={`${match.path}/keuangan/penganggaran/anggaran`} component={Budget} />
                <Route exact path={`${match.path}/keuangan/penganggaran/tambah-anggaran`} component={AddBudget} />
                <Route exact path={`${match.path}/keuangan/penganggaran/tipe-anggaran`} component={BudgetType} />
                <Route exact path={`${match.path}/keuangan/penganggaran/kelola-anggaran`} component={ManageBudget} />
                <Route exact path={`${match.path}/keuangan/penganggaran/rapbu`} component={ManageBudgetRapbu} />
                <Route exact path={`${match.path}/keuangan/penganggaran/upload-rapbu`} component={UploadRapbu} />
                <Route exact path={`${match.path}/keuangan/penganggaran/tambah-upload-rapbu`} component={AddUploadRapbu} />
                <Route exact path={`${match.path}/keuangan/penganggaran/apbu`} component={ManageBudgetRapbu} />
                <Route exact path={`${match.path}/keuangan/penganggaran/rekapitulasi`} component={BudgetRecaps} />

                <Route exact path={`${match.path}/keuangan/penganggaran/non-anggaran`} component={NonBudget} />
                <Route exact path={`${match.path}/keuangan/penganggaran/tambah-non-anggaran`} component={ManageNonBudget} />
                <Route exact path={`${match.path}/keuangan/penganggaran/edit-non-anggaran`} component={ManageNonBudget} />

                <Route exact path={`${match.path}/keuangan/penganggaran/pengalihan-anggaran`} component={BudgetTransfer} />
                <Route exact path={`${match.path}/keuangan/penganggaran/tambah-pengalihan-anggaran`} component={ManageBudgetTransfer} />
                <Route exact path={`${match.path}/keuangan/penganggaran/edit-pengalihan-anggaran`} component={ManageBudgetTransfer} />

                <Route exact path={`${match.path}/keuangan/penganggaran/permohonan-dana`} component={FundRequest} />
                <Route exact path={`${match.path}/keuangan/penganggaran/tambah-permohonan-dana`} component={ManageFundRequest} />
                <Route exact path={`${match.path}/keuangan/penganggaran/edit-permohonan-dana`} component={ManageFundRequest} />

                <Route exact path={`${match.path}/keuangan/penganggaran/realisasi`} component={Realization} />
                <Route exact path={`${match.path}/keuangan/penganggaran/tambah-realisasi`} component={ManageRealization} />
                <Route exact path={`${match.path}/keuangan/penganggaran/edit-realisasi`} component={ManageRealization} />

                <Route exact path={`${match.path}/keuangan/pembukuan`} component={Bookkeeping} />
                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-kas-penutup`} component={CashJournalClosingList} />
                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-kas-penutup/tambah`} component={CashJournalClosing} />
                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-kas`} component={CashJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-kas/:id`} component={DetailCashJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/kelola-jurnal-kas`} component={AddCashJournal} />

                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-bank`} component={BankJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-bank/:id`} component={DetailBankJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/kelola-jurnal-bank`} component={AddBankJournal} />

                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-h2h`} component={H2HJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-h2h/:id`} component={ManageH2HJournal} />

                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-pembayaran`} component={PaymentJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/kelola-jurnal-pembayaran`} component={ManagePaymentJournal} />

                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-penyesuaian`} component={AdjustmentJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/kelola-jurnal-penyesuaian`} component={ManageAdjustmentJournal} />
                  <Route exact path={`${match.path}/keuangan/pembukuan/view-jurnal-penyesuaian`} component={ViewAdjustmentJournal} />

                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-penutup`} component={ClosingJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/kelola-jurnal-penutup`} component={ManageClosingJournal} />

                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-non-kas`} component={NonKasJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/kelola-jurnal-non-kas`} component={ManageNonKasJournal} />

                <Route exact path={`${match.path}/keuangan/pembukuan/jurnal-umum`} component={GeneralJournal} />
                <Route exact path={`${match.path}/keuangan/pembukuan/kelola-jurnal-umum`} component={ManageGeneralJournal} />

                <Route exact path={`${match.path}/keuangan/pembukuan/saldo-awal`} component={BeginningBalance} />

                <Route exact path={`${match.path}/keuangan/laporan/jurnal-kas`} component={CashJournalReport} />
                <Route exact path={`${match.path}/keuangan/laporan/jurnal-bank`} component={BankJournalReport} />
                <Route exact path={`${match.path}/keuangan/laporan/buku-besar`} component={BigBookReport} />
                <Route exact path={`${match.path}/keuangan/laporan/transaksi-bulanan`} component={MonthlyReport} />
                <Route exact path={`${match.path}/keuangan/laporan/jurnal-harian-dan-bulanan`} component={DailyAndMonthly} />
                <Route exact path={`${match.path}/keuangan/laporan/laba-rugi`} component={ProfitAndLost} />
                <Route exact path={`${match.path}/keuangan/laporan/neraca`} component={Balance} />
                <Route exact path={`${match.path}/keuangan/laporan/pembayaran-uang-sekolah`} component={Payment} />
                <Route exact path={`${match.path}/keuangan/laporan/rekap-pembayaran`} component={PaymentRecap} />
                <Route exact path={`${match.path}/keuangan/laporan/supervisi`} component={Supervision} />

                <Route exact path={`${match.path}/pengaturan/ganti-kata-sandi`} component={ManagePassword} />
              </AlertContextProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  onLogout: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  units: PropTypes.object,
  onSearchUnit: PropTypes.func,
  handleSetWorkingUnit: PropTypes.func,
  handleGetNotifications: PropTypes.func,
  handleUpdateNotificationStatus: PropTypes.func,
};
Dashboard.defaultProps = {
  onLogout: noop,
  units: null,
  onSearchUnit: noop,
  handleSetWorkingUnit: noop,
  handleGetNotifications: noop,
  handleUpdateNotificationStatus: noop,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    units: state.options.unit,
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogout: push => dispatch(handleLogout(push)),
    onSearchUnit: payload => dispatch(getUnitOptions(payload)),
    handleSetWorkingUnit: payload => dispatch(setWorkingUnit(payload)),
    handleGetNotifications: payload => dispatch(getNotifications(payload)),
    handleUpdateNotificationStatus: payload => dispatch(updateNotificationStatus(payload)),
    handleAcceptReconciliation: payload => dispatch(journalAcceptReconciliation(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
