// import Http from './http.util';
import * as Http from './axios.util';
import { SERVER_URL_2, SERVER_URL_3, PAYMENT_API } from '../configs/api.config';

export function TEST(data, options = {}) {
  return Http.POST('TEST', data, '', options);
}

export function AUTH(path, options = {}) {
  return Http.GET('AUTH', path, options);
}

export function LOGIN(data) {
  return Http.POST('LOGIN', data);
}

export function PASSWORD(data, path = '') {
  return Http.POST('PASSWORD', data, path);
}

export function PROFILE(path = '', options = {}) {
  return Http.GET('PROFILE', path, options);
}

export function OPTIONS(data, path = '', options = {}) {
  // return Http.POST('OPTIONS', data, path, { ...options, baseURL: SERVER_URL_2 });
  return Http.POST('OPTIONS', data, path, options);
}

export function BUDGET(data, path = '', options = {}) {
  return Http.POST('BUDGET', data, path, options);
}

export function BUDGET_REQUEST(data, path = '', options = {}) {
  return Http.POST('BUDGET_REQUEST', data, path, options);
}

export function NON_BUDGET_POST(data, path = '', options = {}) {
  return Http.POST('NON_BUDGET', data, path, options);
}

export function REALIZATION_POST(data, path = '', options = {}) {
  return Http.POST('REALIZATION', data, path, options);
}

export function FILE(data, path = '', options = {}) {
  return Http.POST('FILE', data, path, options);
}

export function DOWNLOAD(path = '', options = {}) {
  return Http.GET('FILE', path, options);
}

export function PARAM(data, path = '', options = {}) {
  // return Http.POST('PARAM', data, path, options);
  return Http.POST('PARAM', data, path, { ...options, baseURL: SERVER_URL_2 });
}

export function FINANCE_OUTCOME(path = '', options = {}) {
  return Http.GET('FINANCE_OUTCOME', path, options);
}

export function FINANCE_RAPBU(path = '', options = {}) {
  return Http.GET('FINANCE_RAPBU', path, options);
}

export function FINANCE_CASH_JOURNAL(path = '', options = {}) {
  return Http.GET('FINANCE_CASH_JOURNAL', path, options);
}

export function FINANCE_JOURNAL_GET(path = '', options = {}) {
  return Http.GET('FINANCE_JOURNAL', path, options);
}

export function FINANCE_JOURNAL_POST(data, path = '', options = {}) {
  return Http.POST('FINANCE_JOURNAL', data, path, options);
}

export function FINANCE_CASH_JOURNAL_BY_ID(path = '', options = {}) {
  return Http.GET('FINANCE_CASH_JOURNAL_BY_ID', path, options);
}

export function FINANCE_BANK_JOURNAL(path = '', options = {}) {
  return Http.GET('FINANCE_BANK_JOURNAL', path, options);
}

export function FINANCE_BANK_JOURNAL_BY_ID(path = '', options = {}) {
  return Http.GET('FINANCE_BANK_JOURNAL_BY_ID', path, options);
}

export function FINANCE_JOURNAL(path = '', options = {}) {
  return Http.POST('FINANCE_JOURNAL', path, options);
}

export function FINANCE_STARTING_BALANCE(path = '', options = {}) {
  return Http.POST('FINANCE_STARTING_BALANCE', path, options);
}

export function STAFF_POST(data, path = '', options = {}) {
  return Http.POST('STAFF', data, path, { ...options, baseURL: SERVER_URL_2 });
}

export function SK_POST(data, path = '', options = {}) {
  return Http.POST('SK', data, path, { ...options, baseURL: SERVER_URL_2 });
}
export function ST_POST(data, path = '', options = {}) {
  return Http.POST('ST', data, path, { ...options, baseURL: SERVER_URL_2 });
}

export function REPORT_POST(data, path = '', options = {}) {
  return Http.POST('REPORT', data, path, options);
}

export function TAX_POST(data, path = '', options = {}) {
  return Http.POST('TAX', data, path, { ...options, baseURL: SERVER_URL_3 });
}

export function WORKFLOW(data, path = '', options = {}) {
  return Http.POST('WORKFLOW', data, path, options);
}

export function NOTIFICATION(data, path = '', options = {}) {
  return Http.POST('NOTIFICATION', data, path, options);
}

export function NOTIFICATION_STREAM(data, path = '', options = {}) {
  return Http.GET('NOTIFICATION_STREAM', data, path, options);
}

export function PAYMENT_REPORT(data, path = '', options = {}) {
  return Http.POST('PAYMENT_REPORT', data, path, { ...options, baseURL: PAYMENT_API });
}

export function PAYMENT_RECAP(data, path = '', options = {}) {
  return Http.POST('PAYMENT_RECAP', data, path, { ...options, baseURL: PAYMENT_API });
}

export function PAYMENT_OPTIONS(data, path = '', options = {}) {
  return Http.POST('PAYMENT_OPTIONS', data, path, { ...options, baseURL: PAYMENT_API });
}

export function SUPERVISION_REPORT(data, path = '', options = {}) {
  return Http.POST('SUPERVISION_REPORT', data, path, { ...options, baseURL: PAYMENT_API });
}

export function SUPERVISION_RECAP(data, path = '', options = {}) {
  return Http.POST('SUPERVISION_RECAP', data, path, { ...options, baseURL: PAYMENT_API });
}

export function SUPERVISION_OPTIONS(data, path = '', options = {}) {
  return Http.POST('SUPERVISION_OPTIONS', data, path, { ...options, baseURL: PAYMENT_API });
}

export function PAYROLL_POST(data, path = '', options = {}) {
  return Http.POST('PAYROLL', data, path, { ...options, baseURL: SERVER_URL_2 });
}

export function INTERN(data, path = '', options = {}) {
  return Http.POST('INTERN', data, path, { ...options, baseURL: SERVER_URL_2 });
}
